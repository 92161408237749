.video-wrapper{
    height: 100vh;
    background-color: black;

    @media (max-width: $screen-md) {
        height: 50vh;
    }
}

.video-btn{
    width: 100%;
    max-width: 15em;

    @media (max-width: $screen-md) {
        max-width: 10em;
    }
}

.loader-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: black;
    z-index: 9999999999999999999999999999999;
    pointer-events: none;
    transition: opacity .5s;

   
}
.loader-container{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loader{
    width: 100vw;
    height: 100%;
    position: absolute;
}