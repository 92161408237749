.story-wrapper{
    display: flex;
    gap: 1em;
    padding-left: 4vw;
    padding-right: 4vw;
    text-align: center;

    @media (max-width: $screen-md) {
        display: grid;
        gap: 0;
    }

    .title{
        display: block;
        position: relative;
        padding: 2em 4em;
        padding-bottom: 0;
        width: 100%;
        max-width: 30em;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $screen-lg) {
            max-width: 25em;
        }

        @media (max-width: $screen-md) {
            max-width: 25em;
            padding: 2em 0em;
            padding-bottom: 0;
        }

        @media (max-width: $screen-sm) {
            max-width: 15em;
            
        }

        @media (max-width: $screen-phone) {
            max-width: 12em;
            
        }
    }

    .description1{
        display: block;
        position: relative;
        padding: 2em 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        

        @media (max-width: $screen-sm) {
            padding-bottom: 0;
        }
    }

    .description2{
        display: block;
        position: relative;
        padding: 2em 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        max-width: 500px;

        @media (max-width: $screen-md) {
            padding-bottom: 0;
        }
    }
}

.story-img-wrapper{
    width: 50%;
    text-align: center;
    padding: 1em;
    @media (max-width: $screen-md) {
        width: 100%;
        order: 1;
    }


    img{
        width: 100%;
        margin: auto 0;
        position: sticky;
        top: 0em;
        max-width: 50em;
        left: 0;
        right: 0;

        @media (max-width: $screen-md) {
            max-width: 500px;
        }
    }
}

.story-var{
    width: 50%;
    text-align: center;
    padding: 1em;
    @media (max-width: $screen-md) {
        width: 100%;
    }

    
}