.footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner{
        display: flex;
        padding: 1em;

        a{
            margin-left: -0.3em;
        }
        
        img{
            width: 100%;
            max-width: 5em;
            height: 100%;
            cursor: pointer;

            @media (max-width: $screen-sm) {
                
                    max-width: 4em;
              }
        }
    }

    
}


.hidden-img{
    position: absolute;
    left: -999999999999;
    bottom: -9999999999;
    z-index: 99999999999;
    pointer-events: none;
    img{
        width: 0.1em;
    }
}