.header{
  position: fixed;
  z-index: 999;
  top: 0;
  transition: top .4s;
  left: 0;
  right: 0;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  pointer-events: none;

  .inner{
      padding: 1em;
      display: flex;
      justify-content: space-between; 
  }

    nav ul {
      pointer-events: all;
      

        margin: 0;
      
        li {
          display: inline-block;
          position: relative;
          padding: 16px;
          margin: 0;
          text-decoration: none;
          list-style: none;
          cursor: pointer;
          font-size: 18px;
      
        }

        img{
          width: 100%;
          max-width: 10em;
          margin: 0;
          cursor: pointer;
        }
      }

    img{
        width: 10em;
        transition: opacity .4s;

        @media (max-width: $screen-phone) {
          width: 6em;
        }

        
    }

    svg{
      @media (max-width: $screen-phone) {
        width: 2.5em;
      }
    }
}

.null{
  .logo{
    opacity: 1;
}
}

.false{
  top: -100px;

  .logo{
    opacity: 0;
}

}

.hidden{
  top: 0;

  .logo{
    opacity: 0;
}
  
}

.DesktopNav{
  pointer-events: all;
  display: block;
@media (max-width: $screen-md) {
  display: none;
}
}

.MobileNav{
  pointer-events: all;
  display: none;
@media (max-width: $screen-md) {
  display: block;
}
}

.Nav {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 420px;
  background-color: $body-bg;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: transform 0.15s ease-in-out;
  background: $body-bg;
  border-right: 1em solid black;

  @media (max-width: $screen-phone) {
    border-right: none;
  }

  nav ul li{
    display: flex;

    img{

    }
  }

  .border{
    position: absolute;
    height: 100%;
    right: 0;
  }

  @media (max-width: $screen-phone) {
    width: 100%;
  }
}


.Nav--open {
  transform: translateX(0);
}

.nav-header{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.nav-footer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;

  .inner{
      display: flex;
      padding: 1em;

      a{
        margin-left: -0.3em;
      }
      img{
          width: 100%;
          max-width: 4em;
          height: 100%;
          cursor: pointer;
      }
  }
}

.exit-btn-wrapper{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;

  img{
    width: 100%;
    max-width: 4em;
    cursor: pointer;
    
  }

  .exit-inner{
      position: absolute;
  }

  .outer{

  }
}