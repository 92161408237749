.landing-wrapper{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    background-image: url("../../assets/background.png");
    background: url("../../assets/background.png");
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: absolute;
    height: 100vh;
    width: 100%;
    padding-left: 4vw;
    padding-right: 4vw;

    @media (max-width: $screen-phone) {
        display: block;
      }

    @media (max-width: $screen-phone) {
        height: auto;
        padding-top: 140px;
        padding-bottom: 140px;
      }

    footer{
        position: absolute;
        width: 100%;
        padding: 1em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        bottom: 0;

        img{
            width: 20em;
            cursor: pointer;
            @media (max-width: $screen-md) {
                width: 16em;
            }
    
            @media (max-width: $screen-sm) {
                width: 14em;
            }
    
            @media (max-width: $screen-phone) {
                width: 12em;
            }
        }

        @media (max-width: $screen-phone) {
            position: relative;
          }

    }

    .swiper {
        width: 23em;
        height: 100%;
        z-index: 99;

        @media (max-height: $screen-sm) {
            width: 18em;
        }

        @media (max-width: $screen-md) {
            width: 20em;

            @media (max-height: $screen-sm) {
                width: 15em;
            }
            
        }

        @media (max-width: $screen-sm) {
            width: 15em;
        }

        @media (max-width: $screen-phone) {
            width: 12em;
        }

      }
      
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
    
        img{
            width: 100%;
            height: 100%;
        }
      }
    
    .swiper-navigation-wrapper{
        display: flex;    
        gap: 1em;
        justify-content: center;
        padding-bottom: 2em;
        
        img{
            width: 5em;
            cursor: pointer;
            z-index: 99;

            @media (max-height: $screen-sm) {
                width: 4em;
            }
    
            @media (max-width: $screen-md) {
                width: 4em;
            }

            @media (max-width: $screen-sm) {
                width: 3.5em;
            }
    
        }
    
    }

    .play-pause{
        position: absolute;
        bottom: 1.5em;
        cursor: pointer;
    
        @media (max-height: $screen-sm) {
            bottom: 1.3em;
        }
    
        @media (max-width: $screen-md) {
            bottom: 1.3em;
    
            @media (max-height: $screen-sm) {
                bottom: 0.9em;
            }
        }
    
        @media (max-width: $screen-sm) {
            bottom: 0.9em;
        }
    
        @media (max-width: $screen-phone) {
            bottom: 0.9em;
        }
    
        .beat-controls{
            width: 3em !important;
            @media (max-height: $screen-sm) {
                width: 2em !important;
            }
    
            @media (max-width: $screen-md) {
                width: 2.5em !important;
    
                @media (max-height: $screen-sm) {
                    width: 2em !important;
                }
            }
    
            @media (max-width: $screen-sm) {
                width: 2em !important;
            }
    
            @media (max-width: $screen-phone) {
                width: 1.5em !important;
            }
        }
    }
       
}

.swiper-btn-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner{
        position: absolute;
    }

    .outer{

    }
}

.landiingSwiper{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    @media (max-width: $screen-md) {
        width: 100%;
    }
}

