.collection-wrapper{
    display: flex;
    gap: 1em;
    padding-top: 3em;
    padding-left: 4vw;
    padding-right: 4vw;

    @media (max-width: $screen-md) {
        display: block;
        min-height: auto;
    }

    .swiper {
        width: 23em;
        height: 100%;
        z-index: 99;

        @media (max-height: $screen-sm) {
            width: 18em;
        }

        @media (max-width: $screen-md) {
            width: 20em;

            @media (max-height: $screen-sm) {
                width: 15em;
            }
            
        }

        @media (max-width: $screen-sm) {
            width: 15em;
        }

        @media (max-width: $screen-phone) {
            width: 12em;
        }

       
    
      }
      
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
    
        img{
            width: 100%;
            height: 100%;
        }
      }
    
    .swiper-navigation-wrapper{
        display: flex;    
        gap: 1em;
        justify-content: center;
        padding-bottom: 2em;
        img{
            width: 5em;
            cursor: pointer;
            z-index: 99;

            @media (max-height: $screen-sm) {
                width: 4em;
            }
    
            @media (max-width: $screen-md) {
                width: 4em;
            }

            @media (max-width: $screen-sm) {
                width: 3.5em;
            }

            
        }
    
       
    }

    .swiper {
        width: 23em;
        height: 100%;
        z-index: 99;

        @media (max-height: $screen-sm) {
            width: 18em;
        }

        @media (max-width: $screen-md) {
            width: 20em;

            
            
        }

        @media (max-width: $screen-sm) {
            width: 15em;
        }

        @media (max-width: $screen-phone) {
            width: 12em;
        }

       
    
      }
      
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
    
        img{
            width: 100%;
            height: 100%;
        }
      }
    
    .swiper-navigation-wrapper{
        display: flex;    
        gap: 1em;
        justify-content: center;
        padding-bottom: 2em;
        img{
            width: 5em;
            cursor: pointer;
            z-index: 99;

            @media (max-height: $screen-sm) {
                width: 4em;
            }
    
            @media (max-width: $screen-md) {
                width: 4em;
            }

            @media (max-width: $screen-sm) {
                width: 3.5em;
            }

            
        }
    
       
    }
      
    .play-pause{
        position: absolute;
        bottom: 1.5em;
        cursor: pointer;
    
        @media (max-height: $screen-sm) {
            bottom: 1.3em;
        }
    
        @media (max-width: $screen-md) {
            bottom: 1.3em;
    
        
        }
    
        @media (max-width: $screen-sm) {
            bottom: 0.9em;
        }
    
        @media (max-width: $screen-phone) {
            bottom: 0.9em;
        }
    
        .beat-controls{
            width: 3em !important;
            @media (max-height: $screen-sm) {
                width: 2em !important;
            }
    
            @media (max-width: $screen-md) {
                width: 2.5em !important;
    
               
            }
    
            @media (max-width: $screen-sm) {
                width: 2em !important;
            }
    
            @media (max-width: $screen-phone) {
                width: 1.5em !important;
            }
        }
    }
}

.collection-var{
    width: 50%;
    text-align: left;
    padding: 1em;

    .title{
        display: block;
        position: relative;
        padding: 1em 0em;
        width: 100%;
        max-width: 60em;
    }

    .description1{
        display: block;
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 40em;

       
    }

    .description2{
        display: block;
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 35em;
        padding-top: 1em;

       
    }

    @media (max-width: $screen-md) {
        width: 100%;
    }
}

.collectionSwiper{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    @media (max-width: $screen-md) {
        padding-bottom: 2em;
        width: 100%;
    }
}

.collection-marquee{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    padding-top: 5em;
    
    img{
        margin: 1.5em 6em;
        max-width: 9em;

        @media (max-width: $screen-sm) {
            margin: 1.5em 2em;
            max-width: 6em;
        }

        
    }
}

.marquee-desktop{
    display: flex !important;

    @media (max-width: $screen-md) {
        display: none !important ;
    }
}

.marquee-mobile{
display: none !important ;

@media (max-width: $screen-md) {
    display: flex !important;

    .collection-marquee{
        padding-top: 1em;
    }
}
}