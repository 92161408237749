.btn {
  display: inline-block;
  margin-top: 12px;
  background: none;
  text-transform: uppercase;
  background-color: #1B1B1B;
  border: 2px solid #1B1B1B;
  border-radius: 12px;
  padding: 12px 17px;
  font-size: 14px;
  width: auto;
  cursor: pointer;
  color: white;

  @include has-hover {
    &:hover,
    &:focus {
      background-color: darken(#1B1B1B, 10%);
    }
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    border: 1px solid #ccc;

    @include has-hover {
      &:hover,
      &:focus {
        // background-color: darken($white, 10%);
      }
    }    
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: $screen-sm) {
    padding: 8px 17px;
  }
}