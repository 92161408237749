// Account Form stuff
.form-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60vh;
  width: 100%;
  padding-right: 10em;

  @media (max-width: $screen-md) {
    padding-right: 0;
  }
  
  div{
    width: 700px;

    @media (max-width: $screen-md) {
      width: 100%;
    }
  }



  .errors {
    text-align: center;
    margin-bottom: 2rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }
}

.form-group {
  margin-bottom: 1rem;

  label {
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 4px;
  }
}

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #1B1B1B;
  padding: 16px 6px;
  border-radius: 16px !important;
  margin-top: 1em;
  color: #1B1B1B;
  
  &::placeholder {
    color: #1B1B1B;
    opacity: 1; 
    padding-left: 0.5em;
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width: 768px) {
    font-size: 16px;
  }

  &:focus {
    outline: none
  }  

  @media (max-width: $screen-sm) {
    padding: 12px 6px;
  }
  
}

select.form-control {
  background-position: right center;
  padding-right: 34px;
  padding-left: 10px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
}