.team-wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 80px;
    padding-bottom: 80px;
    
    
    .team-container {
        display: grid;
        display: flex;
        flex-wrap:wrap;
        justify-content:center;
        gap: 1em;
        width: 100%;
        max-width: 1200px;
        place-content: center;

        
    }

    .team-polaroid-wrapper{
        position: relative;
        text-align: center;
       
        flex: 0 1 calc(33% - 1rem); /*disable the flex-grow*/

        @media (max-width: $screen-sm) {
            margin: .5rem;
            flex: 0 1 calc(48% - 1rem); /*disable the flex-grow*/
        }

        @media (max-width: $screen-phone) {
            margin: .5rem;
            flex: 0 1 calc(80% - 1rem); /*disable the flex-grow*/
        }
        

        h1{
            position: absolute;
            bottom: 0;
        }

        p{
            padding-top: 1em;
            
        }
    
        img{
            width: 100%;

         
        }
    }

    .title{
        display: block;
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 18em;
        margin-bottom: 5em;

        @media (max-width: $screen-lg) {
            max-width: 15em;
        }

        @media (max-width: $screen-md) {
            max-width: 12em;
        }
        
    }
}

