.roadmap-wrapper{
    min-height: 100vh;
    text-align: center;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: $screen-sm) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .piano{
        padding: 1em;
        width: 100%;
        max-width: 400px; 
    }

    img{
        margin: 0 auto;
        
    }

    .title{
        display: block;
        position: relative;
        padding: 2em 0;
        width: 100%;
        max-width: 25em;


        @media (max-width: $screen-sm) {
            max-width: 15em;
        }

        @media (max-width: $screen-phone) {
            max-width: 12em;
        }
    }

    .description{
        display: block;
        position: relative;
        padding: 2em 0;
        width: 100%;
        max-width: 500px;
    }
}

.keyboard-container{
    position: relative;

    img{
        position: relative;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;

    }

    .key1{
        
        &:hover{
            filter: invert(100%);
        }
    }
}